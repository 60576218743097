import { motion } from "framer-motion";
import { SpecCard } from "./SpecCard";

export const ProductShowcase = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
      className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-16 mobile:mt-12"
    >
      <div className="relative">
        <motion.div
          animate={{
            boxShadow: [
              "0 0 80px rgba(56, 189, 248, 0.2)",
              "0 0 120px rgba(56, 189, 248, 0.4)",
              "0 0 80px rgba(56, 189, 248, 0.2)",
            ],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          className="rounded-3xl overflow-hidden bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 border border-gray-800/50"
        >
          <div className="relative">
            <img
              src="https://placehold.co/800x1000/1a1a2e/cyan?text=Smart+Bottle"
              alt="Smart Water Bottle"
              className="w-full h-auto object-cover mix-blend-luminosity"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-[#0A0A0F] via-transparent to-transparent" />
          </div>
        </motion.div>

        <SpecCard
          color="cyan"
          icon="🌡️"
          title="Temperature"
          value="24°C"
          subtitle="Perfect Cool"
          initialX={-20}
          position="left-4 top-1/4"
        />

        <SpecCard
          color="purple"
          icon="🔋"
          title="Battery Life"
          value="30 Days"
          subtitle="Long Lasting"
          initialX={20}
          position="right-4 top-2/3"
        />
      </div>
    </motion.div>
  );
};
