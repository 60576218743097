import { motion } from "framer-motion";
import PriceCard from "./PriceCard";

const pricingData = [
  {
    tier: "Basic",
    price: "$29",
    features: [
      "24-hour temperature control",
      "Basic hydration tracking",
      "Mobile app access",
      "1-year warranty",
    ],
  },
  {
    tier: "Pro",
    price: "$49",
    features: [
      "Advanced temperature control",
      "AI-powered tracking",
      "Premium mobile features",
      "2-year warranty",
      "Priority support",
    ],
    isPopular: true,
  },
  {
    tier: "Premium",
    price: "$79",
    features: [
      "Ultimate temperature control",
      "Advanced AI analytics",
      "Exclusive mobile features",
      "Lifetime warranty",
      "24/7 VIP support",
      "Custom engraving",
    ],
  },
];

const Pricing = () => {
  return (
    <div className="relative py-24 bg-[#0A0A0F] overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,#1a1a2e_1px,transparent_1px),linear-gradient(-45deg,#1a1a2e_1px,transparent_1px)] bg-[size:30px_30px] opacity-20" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_50%,#1a1a2e,transparent)] opacity-50" />
      </div>

      <div className="relative container mx-auto px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-white mb-4">
            Choose Your Smart Bottle
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Select the perfect smart bottle package that matches your hydration
            needs
          </p>
        </motion.div>

        <div className="desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-1 mobile:grid-cols-1 grid gap-8">
          {pricingData.map((plan, index) => (
            <PriceCard key={plan.tier} {...plan} delay={index * 0.2} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
