import { motion } from "framer-motion";
import { Stat } from "./types";

const stats: Stat[] = [
  {
    value: "24h+",
    label: "Temperature Control",
  },
  {
    value: "99.9%",
    label: "Accuracy Rate",
  },
  {
    value: "50K+",
    label: "Active Users",
  },
];

export const Stats = () => {
  return (
    <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-3 mobile:grid-cols-2 gap-10">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 + index * 0.1 }}
          className="relative group"
        >
          <motion.div
            animate={{ opacity: [0.3, 0.5, 0.3] }}
            transition={{
              duration: 3,
              delay: index * 0.5,
              repeat: Infinity,
            }}
            className="absolute -inset-4 rounded-lg bg-gradient-to-r from-cyan-500/10 to-purple-500/10 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"
          />
          <div className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent mb-2">
            {stat.value}
          </div>
          <div className="text-sm text-gray-400 font-medium">{stat.label}</div>
        </motion.div>
      ))}
    </div>
  );
};
