import { motion } from "framer-motion";
import { FiCheck } from "react-icons/fi";

interface PriceCardProps {
  tier: string;
  price: string;
  features: string[];
  isPopular?: boolean;
  delay?: number;
}

const PriceCard = ({
  tier,
  price,
  features,
  isPopular,
  delay = 0,
}: PriceCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ scale: 1.02 }}
      className="relative p-8 rounded-2xl bg-gray-900/90 backdrop-blur-xl border border-gray-800/50"
    >
      {isPopular && (
        <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full">
          <span className="text-sm font-medium text-white">Most Popular</span>
        </div>
      )}

      <div className="text-cyan-400 font-medium mb-4">{tier}</div>
      <div className="flex items-baseline mb-8">
        <span className="text-4xl font-bold text-white">{price}</span>
        <span className="text-gray-400 ml-2">/month</span>
      </div>

      <div className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center gap-3">
            <FiCheck className="text-cyan-400" />
            <span className="text-gray-400">{feature}</span>
          </div>
        ))}
      </div>

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="w-full py-4 px-8 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl text-white font-semibold"
      >
        Get Started
      </motion.button>
    </motion.div>
  );
};

export default PriceCard;
