import { motion } from "framer-motion";
import { SpecCard as SpecCardType } from "./types";

export const SpecCard = ({
  color,
  icon,
  title,
  value,
  subtitle,
  initialX,
  position,
}: SpecCardType) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: initialX }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.8 }}
      className={`absolute ${position} bg-gray-900/90 backdrop-blur-xl border border-gray-800/50 p-6 rounded-2xl`}
    >
      <div className="flex items-center gap-3 mb-3">
        <motion.div
          animate={{ scale: [1, 1.2, 1] }}
          transition={{ duration: 2, repeat: Infinity }}
          className={`h-3 w-3 rounded-full bg-${color}-400`}
        />
        <div className={`text-${color}-400 font-medium`}>{title}</div>
      </div>
      <div className="text-white text-3xl font-bold">{value}</div>
      <div className="text-gray-500 text-sm mt-1">{subtitle}</div>
    </motion.div>
  );
};
