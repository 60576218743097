import { motion } from "framer-motion";

export const Badge = () => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="inline-flex items-center gap-3 bg-cyan-950/40 border border-cyan-500/20 rounded-full px-5 py-2.5 mb-10"
    >
      <motion.div
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ duration: 2, repeat: Infinity }}
        className="flex gap-1"
      >
        <div className="h-2 w-2 rounded-full bg-cyan-400" />
        <div className="h-2 w-2 rounded-full bg-cyan-400 opacity-60" />
        <div className="h-2 w-2 rounded-full bg-cyan-400 opacity-30" />
      </motion.div>
      <span className="text-cyan-400 font-medium tracking-wider text-sm">
        HYDRATION REINVENTED
      </span>
    </motion.div>
  );
};
