import React from "react";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing/Pricing";

const Home: React.FC = () => {
  return (
    <>
      {" "}
      <Hero />
      <Pricing />
    </>
  );
};

export default Home;
