import { motion } from "framer-motion";

export const Headline = () => {
  return (
    <h1 className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-bold mb-8">
      <div className="text-white leading-[1.1] mb-3">The Future of</div>
      <div className="relative">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-400 via-blue-400 to-purple-500 leading-[1.1]">
          Smart Hydration
        </span>
        <motion.div
          animate={{ opacity: [0.2, 0.4, 0.2] }}
          transition={{ duration: 3, repeat: Infinity }}
          className="absolute -inset-1 bg-gradient-to-r from-cyan-400 via-blue-400 to-purple-500 blur-2xl opacity-20"
        />
      </div>
    </h1>
  );
};
