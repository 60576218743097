import { Background } from "./Background";
import { Badge } from "./Badge";
import { CTAs } from "./CTAs";
import { Headline } from "./Headline";
import { ProductShowcase } from "./ProductShowcase";
import { Stats } from "./Stats";
import { motion } from "framer-motion";

export const Hero = () => {
  return (
    <div className="relative min-h-screen bg-[#0A0A0F] overflow-hidden">
      <Background />

      <div className="relative container mx-auto px-6 pt-24">
        <div className="desktop:flex laptop:flex tablet:block mobile:block items-center justify-between gap-24">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full"
          >
            <Badge />
            <Headline />
            <p className="desktop:text-xl laptop:text-lg tablet:text-base mobile:text-sm text-gray-400 mb-12 leading-relaxed max-w-xl">
              Experience the perfect blend of technology and hydration. Our
              smart bottles feature precision temperature control, AI-powered
              tracking, and seamless connectivity for an unmatched drinking
              experience.
            </p>
            <CTAs />
            <Stats />
          </motion.div>

          <ProductShowcase />
        </div>
      </div>
    </div>
  );
};
