import { motion } from "framer-motion";

export const CTAs = () => {
  return (
    <div className="flex gap-8 items-center mb-16">
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="group relative overflow-hidden px-8 py-4 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-xl"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-blue-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300 blur-xl" />
        <div className="absolute inset-0 bg-gradient-to-r from-cyan-600 to-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        <span className="relative text-white font-semibold">
          Shop Collection
        </span>
      </motion.button>

      <motion.div
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="group cursor-pointer flex items-center gap-4"
      >
        <div className="relative h-14 w-14 rounded-full bg-gray-800/50 backdrop-blur-sm flex items-center justify-center border border-gray-700 group-hover:border-cyan-500/50 transition-all duration-300">
          <motion.div
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 2, repeat: Infinity }}
            className="text-cyan-400"
          >
            ▶
          </motion.div>
          <div className="absolute inset-0 rounded-full bg-cyan-500/20 group-hover:bg-cyan-500/30 blur-md transition-all duration-300" />
        </div>
        <span className="text-gray-400 font-medium group-hover:text-cyan-400 transition-colors duration-300">
          Watch Demo
        </span>
      </motion.div>
    </div>
  );
};
