import { motion } from "framer-motion";

export const Background = () => {
  return (
    <>
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(45deg,#1a1a2e_1px,transparent_1px),linear-gradient(-45deg,#1a1a2e_1px,transparent_1px)] bg-[size:30px_30px] opacity-20" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_500px_at_50%_50%,#1a1a2e,transparent)] opacity-50" />
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.3, 0.5, 0.3] }}
        transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-cyan-500 to-transparent blur-sm"
      />

      <div className="absolute inset-0">
        <motion.div
          animate={{
            opacity: [0.2, 0.4, 0.2],
            scale: [1, 1.2, 1],
          }}
          transition={{ duration: 10, repeat: Infinity, ease: "easeInOut" }}
          className="absolute h-[800px] w-[800px] top-[-400px] left-[-200px] rounded-full bg-cyan-500/10 blur-[150px]"
        />
        <motion.div
          animate={{
            opacity: [0.2, 0.4, 0.2],
            scale: [1.2, 1, 1.2],
          }}
          transition={{
            duration: 10,
            delay: 5,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          className="absolute h-[800px] w-[800px] bottom-[-400px] right-[-200px] rounded-full bg-purple-500/10 blur-[150px]"
        />
      </div>
    </>
  );
};
